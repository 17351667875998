<template>
    <div class="update-trade-in">
        <div class="row">
            <div class="col-md-12">
                <KTCodePreview v-bind:title="'Thêm mới phiếu định giá'">
                    <template v-slot:preview>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="col-md-12 mb-5">
                                <div class="row">
                                    <p
                                        class="col-md-6 pl-0 font-weight-bolder"
                                        style="color: red; font-size: 14px"
                                    >
                                        Thông tin khách hàng:
                                    </p>
                                    <div class="col-md-1"></div>
                                    <div class="col-md-5">
                                        <p
                                            class="row pl-0 font-weight-bolder"
                                            style="color: red; font-size: 14px"
                                        >
                                            Sản phẩm định giá:
                                        </p>
                                    </div>
                                </div>
                                <div class="row pl-0 mt-4">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <label
                                                style="font-weight: 600"
                                                for="input-status"
                                                class="col-md-3 pl-0"
                                                >Điện thoại:<b class="text-danger">*</b></label
                                            >
                                            <b-col md="6">
                                                <Autosuggest
                                                    :model="customerModel.customerMobile"
                                                    :suggestions="filteredOptionsCustomer"
                                                    placeholder="SĐT khách hàng"
                                                    :limit="10"
                                                    @select="onSelectedCustomer"
                                                    :suggestionName="'phoneNo'"
                                                    @change="onInputChangeCustomer"
                                                >
                                                    <template #custom="{ suggestion }">
                                                        <div>
                                                            <div class="d-flex flex-column">
                                                                <span
                                                                    class="text-primary mb-1"
                                                                    v-if="suggestion.item.phoneNo"
                                                                    >({{
                                                                        suggestion.item.phoneNo
                                                                    }})</span
                                                                >
                                                                <span
                                                                    v-if="suggestion.item.fullName"
                                                                    >{{
                                                                        suggestion.item.fullName
                                                                    }}</span
                                                                >
                                                                <span v-else>{{
                                                                    suggestion.item.message
                                                                }}</span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </Autosuggest>
                                            </b-col>
                                        </div>
                                    </div>
                                    <div class="col-md-1"></div>
                                    <div class="col-md-5">
                                        <div class="row">
                                            <label
                                                style="font-weight: 600"
                                                for="input-status"
                                                class="col-md-4 pl-0"
                                                >Chương trình:</label
                                            >
                                            <b-col md="6">
                                                <Autosuggest
                                                    :model="!isDisabled ? searchTradeInProgram : ''"
                                                    :suggestions="filteredOptionsTradeInProgram"
                                                    :placeholder="'tên chương trình'"
                                                    :limit="10"
                                                    @select="onSelectedTradeInProgram"
                                                    @change="onInputChangeTradeInProgram"
                                                    :disabled="!isDisabled"
                                                />
                                            </b-col>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pl-0 mt-4">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <label
                                                style="font-weight: 600"
                                                for="input-status"
                                                class="col-md-3 pl-0"
                                                >Khách hàng:<b class="text-danger">*</b></label
                                            >
                                            <b-col md="6">
                                                <b-form-input
                                                    class="input-style"
                                                    size="sm"
                                                    type="text"
                                                    v-model="customerModel.customerName"
                                                    placeholder="Tên khách hàng"
                                                ></b-form-input>
                                            </b-col>
                                        </div>
                                    </div>
                                    <div class="col-md-1"></div>
                                    <div class="col-md-5">
                                        <div class="row">
                                            <label
                                                style="font-weight: 600"
                                                for="input-status"
                                                class="col-md-4 pl-0"
                                                >Sản phẩm:<b class="text-danger">*</b></label
                                            >
                                            <b-col md="6">
                                                <Autosuggest
                                                    :model="searchProduct"
                                                    :suggestions="filteredOptionsProduct"
                                                    :placeholder="'tên sản phẩm'"
                                                    :limit="10"
                                                    @select="onSelectedProduct"
                                                    @change="onInputChangeProduct"
                                                />
                                            </b-col>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pl-0 mt-4">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <label
                                                for="input-status"
                                                class="col-md-3 pl-0 font-weight-bolder"
                                                >Số CCCD/CMND:</label
                                            >
                                            <b-col md="6">
                                                <b-form-input
                                                    size="sm"
                                                    type="text"
                                                    v-model="customerIdentifyNo"
                                                    required
                                                    placeholder="Nhập CCCD/CMND"
                                                    class="mb-3"
                                                ></b-form-input>
                                            </b-col>
                                        </div>
                                    </div>
                                    <div class="col-md-1"></div>
                                    <div class="col-md-5">
                                        <div class="row">
                                            <label
                                                for="input-status"
                                                class="col-md-4 pl-0 font-weight-bolder"
                                                >IMEI:<b class="text-danger">*</b></label
                                            >
                                            <b-col md="6">
                                                <b-form-input
                                                    size="sm"
                                                    type="text"
                                                    v-model="searchImei"
                                                    required
                                                    placeholder="Mã IMEI"
                                                    class="mb-3"
                                                ></b-form-input>
                                            </b-col>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pl-0 mt-4">
                                    <div class="col-md-6"></div>
                                    <div class="col-md-1"></div>
                                    <div class="col-md-5">
                                        <div class="row">
                                            <label
                                                style="font-weight: 600"
                                                for="input-status"
                                                class="col-md-4 pl-0"
                                                >Loại:</label
                                            >
                                            <b-col md="6">
                                                <b-form-select
                                                    class="select-style"
                                                    size="sm"
                                                    id="input-3"
                                                    v-model="selectedType"
                                                    :options="listType"
                                                    required
                                                    value-field="id"
                                                    text-field="name"
                                                    disabled-field="notEnabled"
                                                ></b-form-select>
                                            </b-col>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pl-0 my-7"></div>
                                <div class="row pl-0 mt-4">
                                    <div class="col-md-6">
                                        <div
                                            class="row"
                                            v-for="(item, index) in listCreteriaGroup"
                                            :key="index"
                                            :group="item"
                                        >
                                            <div>
                                                <label
                                                    style="font-weight: 600"
                                                    for="input-status"
                                                    class="pl-0"
                                                    >{{ item.criteriaGroupName }}</label
                                                >
                                                <b-badge
                                                    :variant="
                                                        item.type === 1 ? 'secondary' : 'primary'
                                                    "
                                                    class="ml-2"
                                                    >{{
                                                        item.type === 1
                                                            ? '1 lựa chọn'
                                                            : 'Nhiều lựa chọn'
                                                    }}
                                                </b-badge>
                                            </div>
                                            <!-- feature-flags = true -->
                                            <b-table
                                                class="table-bordered table-hover col-md-12"
                                                :fields="computedFields"
                                                :items="item.listItem"
                                            >
                                                <template v-slot:cell(amount)="row">
                                                    <div class="row d-flex justify-content-end">
                                                        <div
                                                            class="d-flex justify-content-end pr-2"
                                                        >
                                                            <span v-if="row.item.operator === 1"
                                                                >(+)
                                                            </span>
                                                            <span v-if="row.item.operator === 2"
                                                                >(-)
                                                            </span>
                                                            <span
                                                                v-text="
                                                                    convertPrice(row.item.amount)
                                                                "
                                                            ></span>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-slot:cell(selected)="row">
                                                    <div style="text-align: center">
                                                        <b-form-checkbox
                                                            v-if="item.type === 2"
                                                            :id="`${row.item.id}`"
                                                            v-model="row.item.selected"
                                                            name="checkbox-1"
                                                        >
                                                        </b-form-checkbox>
                                                        <b-form-radio
                                                            v-if="item.type === 1"
                                                            v-model="item.selectedRadio"
                                                            :id="`radio-${row.item.id}`"
                                                            :name="`radio-${item.id}`"
                                                            :value="row.item.id"
                                                        ></b-form-radio>
                                                    </div>
                                                </template>
                                                <template v-slot:cell(adjustAmount)="row">
                                                    <div class="row d-flex justify-content-end">
                                                        <div
                                                            class="d-flex justify-content-end pr-2"
                                                        >
                                                            <span
                                                                v-if="row.item.adjustOperator === 1"
                                                                >(+)
                                                            </span>
                                                            <span
                                                                v-if="row.item.adjustOperator === 2"
                                                                >(-)
                                                            </span>
                                                            <span
                                                                v-text="
                                                                    convertPrice(
                                                                        row.item.adjustAmount,
                                                                    )
                                                                "
                                                            ></span>
                                                        </div>
                                                    </div>
                                                </template>
                                            </b-table>
                                            <!--  feature-flags -->
                                        </div>
                                    </div>
                                    <div class="col-md-1"></div>
                                    <div class="col-md-4">
                                        <div class="row">
                                            <label
                                                style="font-weight: 600"
                                                for="input-status"
                                                class="col-md-12 pl-0"
                                                >Tổng quan:</label
                                            >
                                        </div>
                                        <div class="row mt-4">
                                            <label
                                                style="font-weight: 600"
                                                for="input-status"
                                                class="col-md-6 pl-0"
                                                >Giá thu trên sản phẩm:</label
                                            >
                                            <span>{{ convertPrice(productBuyingPrice) }}</span>
                                        </div>
                                        <div class="row mt-4">
                                            <label
                                                style="font-weight: 600"
                                                for="input-status"
                                                class="col-md-6 pl-0"
                                                >Phát sinh sau đánh giá:</label
                                            >
                                            <span :class="statusClass">{{
                                                convertPrice(totalCriteriaPriceComputed)
                                            }}</span>
                                        </div>
                                        <div class="row mt-4">
                                            <label
                                                style="font-weight: 600"
                                                for="input-status"
                                                class="col-md-6 pl-0"
                                                >Giá thu dự kiến:</label
                                            >
                                            <span
                                                ><mark>{{
                                                    convertPrice(estimationBuydingPrice)
                                                }}</mark></span
                                            >
                                        </div>
                                        <div class="row mt-4">
                                            <label
                                                style="font-weight: 600"
                                                for="input-status"
                                                class="col-md-6 pl-0"
                                                >Giá thu cuối cùng:</label
                                            >
                                            <b-form-input
                                                size="sm"
                                                type="text"
                                                v-model="finalBuyingPrice"
                                                required
                                                placeholder="Giá thu"
                                                class="mb-3"
                                                v-mask="mask"
                                            ></b-form-input>
                                        </div>
                                        <div class="row mt-4">
                                            <label style="font-weight: 600" for="input-description"
                                                >Ghi chú:</label
                                            >
                                        </div>
                                        <div class="row">
                                            <b-form-textarea
                                                id="textarea-description"
                                                v-model="note"
                                                placeholder="Ghi chú"
                                                rows="5"
                                            ></b-form-textarea>
                                        </div>
                                        <div class="row" style="padding-left: 0px">
                                            <div class="mb-5 mt-5">
                                                <button
                                                    type="button"
                                                    class="btn btn-primary font-weight-bolder btn-sm button mr-4"
                                                    @click="onSubmit"
                                                >
                                                    Lưu phiếu
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-form>
                    </template>
                    <template v-slot:foot>
                        <!-- <b-col lg="4" class="pb-2"> -->
                        <router-link to="/trade-in" tag="button">
                            <b-button
                                style="font-weight: 600; width: 70px"
                                variant="secondary"
                                size="sm"
                                >Hủy</b-button
                            >
                        </router-link>
                    </template>
                </KTCodePreview>
            </div>
        </div>
    </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import ApiService from '@/core/services/api.service';
import decounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import {
    convertPrice,
    isFeatureActive,
    makeToastFaile,
    makeToastSuccess,
    currencyMask,
    unMaskPrice,
} from '@/utils/common';
import { TRADE_IN_TYPE } from '@/utils/constants';
// import _ from 'lodash';

export default {
    mixins: [validationMixin],
    data() {
        return {
            valid: true,
            mask: currencyMask,
            listStatus: [{ id: 1, name: 'Đã báo giá' }],
            selectedId: null,
            options: [
                {
                    data: [],
                },
            ],
            productTradeInId: null,
            filteredOptionsProduct: [],
            optionsProduct: [
                {
                    data: [],
                },
            ],
            searchProduct: '',
            searchPhoneNo: '',
            filteredOptionsTradeInProgram: [],
            optionsTradeInProgram: [
                {
                    data: [],
                },
            ],
            searchCustomer: '',
            customerModel: {
                customerMobile: '',
                customerName: '',
            },
            filteredOptionsCustomer: [],
            optionsCustomer: [],
            searchTradeInProgram: 'Nhập tên chương trình',
            tradeInProgramId: null,
            filteredOptionsStore: [],
            optionsStore: [
                {
                    data: [],
                },
            ],
            searchImei: '',
            createdBy: '',
            createdAt: '',
            listGroupOption: [],
            listCriterialOption: [],
            listCreteriaGroup: [],
            fields: [
                {
                    key: 'creteriaName',
                    label: 'Tiêu chí',
                    sortable: false,
                    thStyle: {
                        fontWeight: 600,
                        color: '#181c32',
                        textAlign: 'center',
                        width: '50%',
                    },
                    tdClass: 'text-left',
                    enableFeature: false,
                },
                {
                    key: 'selected',
                    label: 'Lựa chọn',
                    sortable: false,
                    thStyle: {
                        fontWeight: 600,
                        color: '#181c32',
                        textAlign: 'center',
                        width: '20%',
                    },
                    tdClass: 'text-left',
                    enableFeature: false,
                },
                {
                    key: 'amount',
                    label: 'Giá trị',
                    sortable: false,
                    thStyle: {
                        fontWeight: 600,
                        color: '#181c32',
                        textAlign: 'center',
                        width: '15%',
                    },
                    tdClass: 'text-left',
                    enableFeature: false,
                },
                {
                    key: 'adjustAmount',
                    label: 'Giá trị 2',
                    sortable: false,
                    thStyle: {
                        fontWeight: 600,
                        color: '#181c32',
                        textAlign: 'center',
                        width: '15%',
                    },
                    tdClass: 'text-left',
                    enableFeature: true,
                },
            ],
            productBuyingPrice: 0,
            finalBuyingPrice: null,
            estimationBuydingPrice: 0,
            totalCriteriaPrice: 0,
            note: '',
            selectedType: 1,
            listType: TRADE_IN_TYPE,
            isDisabled: false,
            customerId: null,
            customerIdentifyNo: null,
            tradeInAddAdjustamount: false,
        };
    },
    validations: {
        form: {},
    },
    components: {
        KTCodePreview,
        Autosuggest,
    },
    async created() {
        this.tradeInAddAdjustamount = await isFeatureActive('trade-in-add-adjustamount');
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: 'Thu cũ- đổi mới', route: '/trade-in' },
            { title: 'Danh sách phiếu thu cũ', route: '/import-trade-in' },
            { title: 'Tạo phiếu định giá' },
        ]);
    },
    watch: {
        // listCreteriaGroup: {
        //     handler(val) {},
        //     deep: true,
        // },
        totalCriteriaPriceComputed(newVal) {
            this.totalCriteriaPrice = newVal;
        },
        estimationBuydingPriceComputed(newVal) {
            this.estimationBuydingPrice = newVal;
        },
    },
    methods: {
        openFileModal() {
            this.$refs['file-modal'].show();
        },
        validatePayload(dataset) {
            if (!dataset.tradeInProgramId) {
                return [false, 'Thiếu chương trình thu cũ'];
            }
            if (!dataset.imei) {
                const isValid = /^[a-zA-Z0-9]+$/.test(dataset.imei);
                return [isValid, 'Mã IMEI không hợp lệ, vui lòng kiểm tra lại!'];
            }

            if (!dataset.customer.phoneNo) {
                const isValid = /^\+?(\d{1,3})?[-. ]?(\d{1,4})[-. ]?\d{3,4}[-. ]?\d{4}$/.test(
                    dataset.customer.phoneNo,
                );
                return [isValid, 'Vui lòng kiểm tra lại số điện thoại khách hàng!'];
            }
            if (!dataset.customer.fullName) {
                return [false, 'Vui lòng nhập tên khách hàng!'];
            }
            if (!dataset.finalBuyingPrice) {
                return [false, 'Vui lòng nhập giá thu mua cuối cùng!'];
            }
            return [true, 'Dữ liệu hợp lệ'];
        },
        convertPrice,
        onSelectedProduct(option) {
            this.searchProduct = option.item.productName;
            let productId = option.item.id;
            this.productTradeInId = productId;
            ApiService.setHeader();
            ApiService.get(`tradeInProgram/mobile/by-product-id?productId=${productId}`)
                .then(({ data }) => {
                    if (data.status === 1) {
                        makeToastSuccess('Tìm kiếm chương trình thu cũ thành công!');
                        this.searchTradeInProgram = data.data.name || 'Nhập tên chương trình';
                        this.tradeInProgramId = data.data.id;
                        this.listGroupOption = data.data.tradeInProgramCriteriaGroup;
                        this.productBuyingPrice = data.data.buyingPrice;
                        this.listCreteriaGroup = data.data.tradeInProgramCriteriaGroup.map(
                            (group) => ({
                                ...group,
                                selectedRadio: '',
                                listItem: group.tradeInProgramCreteriaGroupOptions.map(
                                    (option) => ({
                                        ...option,
                                        selected: false,
                                    }),
                                ),
                                tradeInProgramCreteriaGroupOptions: undefined, // Xóa trường cũ (nếu không cần giữ lại)
                            }),
                        );
                        // this.totalCriteriaPrice = data.data.totalCriteriaPrice;
                        // this.estimationBuydingPrice = data.data.estimationBuydingPrice;
                        // this.finalBuyingPrice = data.data.finalBuyingPrice;
                    } else {
                        makeToastFaile(data.messasge);
                        this.searchTradeInProgram = 'Nhập tên chương trình';
                        this.tradeInProgramId = null;
                        this.listGroupOption = null;
                        this.productBuyingPrice = null;
                        this.listCreteriaGroup = null;
                    }
                })
                .catch(({ response }) => {
                    makeToastFaile(response.data.message);
                    this.searchTradeInProgram = 'Nhập tên chương trình';
                    this.tradeInProgramId = null;
                    this.listGroupOption = null;
                    this.productBuyingPrice = null;
                    this.listCreteriaGroup = null;
                });
        },
        onInputChangeProduct(text) {
            if (!text) {
                text = '';
            }
            this.searchProduct = text;
            this.debounceProduct();
        },
        onSelectedTradeInProgram(option) {
            this.searchTradeInProgram = option.item.name;
        },
        onInputChangeTradeInProgram(text) {
            if (!text) {
                text = '';
            }
            this.searchTradeInProgram = text;
        },
        onSelectedCustomer(option) {
            if (option.item.id) {
                this.customerModel.customerMobile = option.item.phoneNo;
                this.customerModel.customerName = option.item.fullName;
                this.customerIdentifyNo = option.item.indentifyNo;
            } else {
                this.customerModel.customerMobile = option.item.phoneNo;
                this.customerModel.customerName = '';
            }
        },
        onInputChangeCustomer(text) {
            if (!text) {
                text = '';
            }
            this.customerModel.customerMobile = text;

            this.optionsCustomer = [];
            this.filteredOptionsCustomer = [...this.optionsCustomer];
            this.debounceInput();
        },
        onSelectedStore(option) {
            this.searchStore = option.item.name;
        },
        onInputChangeStore(text) {
            if (!text) {
                text = '';
            }
            this.searchStore = text;
        },
        fetchProduct(textSearch) {
            this.optionsProduct[0].data = [];
            ApiService.setHeader();
            ApiService.get(`tradeInProgram/mobile/by-product-name?productName=${textSearch}`).then(
                ({ data }) => {
                    const products = data.data;
                    products.map((element) => {
                        this.optionsProduct[0].data.push(element);
                    });
                    this.filteredOptionsProduct = this.optionsProduct[0].data.map((product) => ({
                        ...product,
                        name: product.productName,
                    }));

                    if (textSearch !== this.searchProduct) {
                        let nextTextSearch = this.searchProduct;
                        this.fetchProduct(nextTextSearch);
                    }
                },
            );
        },
        getListCustomer() {
            const text = this.customerModel.customerMobile
                ? this.customerModel.customerMobile.trim()
                : null;
            if (!text || text.length < 5) {
                return;
            }
            ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then((data) => {
                this.optionsCustomer = data.data.data;
                if (this.optionsCustomer.length > 0) {
                    this.filteredOptionsCustomer = [...this.optionsCustomer];
                } else {
                    this.filteredOptionsCustomer = [
                        { message: 'SĐT không có trong hệ thống', phoneNo: text },
                    ];
                }
            });
        },
        debounceInput: decounce(function () {
            this.getListCustomer();
        }, TIME_TRIGGER),
        debounceProduct: decounce(function () {
            this.fetchProduct();
        }, TIME_TRIGGER),
        onSubmit: async function () {   
            let criteriaGroupsData = this.listCreteriaGroup
                .map((item) => {
                    if (item.type === 1) {
                        // Tìm phần tử trong listItem có id = selectedRadio
                        const selectedItem = item.listItem.find(
                            (listItem) => listItem.id === item.selectedRadio,
                        );
                        return selectedItem || null;
                    } else {
                        // Nếu selectedRadio không có giá trị, lọc các phần tử có selected = true
                        return item.listItem.filter(
                            (listItem) => listItem.selected === true,
                        );
                    }
                })
                .flat()
                .filter(Boolean);

            const data = {
                tradeInProgramId: this.tradeInProgramId,
                imei: this.searchImei,
                productId: this.productTradeInId,
                productBuyingPrice: this.productBuyingPrice,
                totalCriteriaPrice: this.totalCriteriaPrice,
                finalBuyingPrice: unMaskPrice(this.finalBuyingPrice),
                customer: {
                    fullName: this.customerModel.customerName,
                    phoneNo: this.customerModel.customerMobile,
                    indentifyNo: this.customerIdentifyNo,
                },
                criteriaGroups: criteriaGroupsData,
                note: this.note,
                typeTradeIn: this.selectedType,
            };
            const [isValid, message] = this.validatePayload(data);
            if (!isValid) {
                return makeToastFaile(message);
            }
            ApiService.setHeader();
            ApiService.post('tradeIn/mobile', data)
                .then((response) => {
                    const status = response.data.status;
                    const message = response.data.message;

                    if (status === 1) {
                        makeToastSuccess(message);
                        history.back();
                    } else {
                        makeToastFaile(message);
                    }
                })
                .catch((err) => {
                    const message = err.data.message;
                    makeToastFaile(message);
                });
        },
    },
    computed: {
        computedFields() {
            if (this.tradeInAddAdjustamount) {
                return this.fields;
            } else {
                return this.fields.filter((field) => !field.enableFeature);
            }
        },
        totalCriteriaPriceComputed() {
            if (!this.listCreteriaGroup) {
                return 0;
            }

            return this.listCreteriaGroup.reduce((total, group) => {
                if (group.type === 1 && group.selectedRadio) {
                    // Tìm item được chọn cho group.type === 1
                    const selectedId = parseInt(group.selectedRadio, 10);
                    const selectedItem = group.listItem.find((item) => item.id === selectedId);
                    if (selectedItem) {
                        total +=
                            selectedItem.operator === 1
                                ? selectedItem.amount
                                : -selectedItem.amount;
                    }
                } else if (group.type === 2) {
                    // Tính tổng cho các item được chọn (selected === true)
                    total += group.listItem
                        .filter((item) => item.selected)
                        .reduce((groupTotal, item) => {
                            return groupTotal + (item.operator === 1 ? item.amount : -item.amount);
                        }, 0);
                }
                return total;
            }, 0);
        },
        estimationBuydingPriceComputed() {
            return this.productBuyingPrice + this.totalCriteriaPriceComputed;
        },
        statusClass() {
            if (this.totalCriteriaPrice > 0) return 'text-success';
            if (this.totalCriteriaPrice < 0) return 'text-danger';
            return 'text-dark';
        },
    },
};
</script>

<style lang="scss">
.update-trade-in {
    input::-webkit-calendar-picker-indicator {
        display: none;
    }

    .label-input {
        font-weight: 500;
    }

    .alert-header {
        position: relative;
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
    }
}
</style>
